<template>
    <div id="bekuldeseim" class="my-sendings mb-5 pt-0">
        <div class="container-xl">
            
            <div class="row">
                <div class="col-12">
                    <h2 class="font-header display-2 fw-900 text-primary text-uppercase text-center mb-3 mb-md-5 mt-0 mt-md-5">
                        Beküldéseim
                    </h2>
                </div>
            </div>
            
            <div class="row" >                
                <div 
                    class="col-12 col-xxl-10 offset-xxl-1"
                >               
                    <div class="bg-light form-inner-container-small form-inner-container-shadow my-sendings__table">
                        <!-- Sendings table -->
                        <div                            
                            class="row d-none d-md-flex"
                        >
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0">
                                <p class="fw-bold text-center"
                                    style="height:40px">AP Kód</p>
                            </div>
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0">
                                <p class="fw-bold text-center"
                                    style="height:40px">NAV Kód</p>
                            </div>
                             <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0">
                                <p class="fw-bold text-center"
                                    style="height:40px">Rendelés száma</p>
                            </div>
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0">
                                <p class="fw-bold text-center"
                                    style="height:40px">Vásárlás dátuma</p>
                            </div>
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0">
                                <p class="fw-bold text-center"
                                    style="height:40px">Vásárlás időpontja</p>
                            </div>
                            <div class="col-12 col-md-2 flex-shrink-0 flex-grow-0">
                                <p class="fw-bold text-center"
                                    style="height:40px">Beküldés dátuma</p>
                            </div>
                        </div>                    
                        <div v-if="sedings.length" >
                            <div  
                                v-for="(item, idx) in sedings"
                                :key="'item' + idx"
                                class="row my-sendings__table-row"
                            >
                                <div class="col-12 col-md-2">
                                    <p class="text-center">{{ item.ap_code}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center">{{ item.nav_code}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center">{{ item.online_code}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center">{{ item.buy_date}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center">{{ item.buy_time}}</p>
                                </div>
                                <div class="col-12 col-md-2">
                                    <p class="text-center">{{ item.send_day}}</p>
                                </div>
                            </div>
                        </div>
                        <div 
                            class="text-center"
                            v-else
                        >
                            Még nincs felöltött blokk
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {           
           
            sedings:[]
        }
    },
    props:{
        getMyNewSendings:{
            type: Boolean,
            required:false
        }
    },
    watch:{
        getMyNewSendings(){
            this.getMySendings()
        }
    },
    methods:{
        getMySendings(){
            this.get('profile/applicants',{})
                .then((resp) => {
                    this.sedings = resp.data.applicants                    
                }).catch((err) => {
                    console.log(err)
                })
        }
    },
    created(){
        this.getMySendings()
    }
}
</script>