<template>
    <main>
        <Home />                
    </main>
</template>


<script>
//TODO: checkloggedin ? most a home.vue-t több helyen húzom be emiatt...

import Home from '@/views/Home.vue'

export default {
    components:{
        Home        
    },    
    mounted(){                  

        if (this.$route.params.scrollTo){            
            setTimeout(() => {
                var element = document.getElementById(this.$route.params.scrollTo)
                element.scrollIntoView()       
            }, 1000);
            
        }

        if (this.$route.params.name != 'EmailVerification'){          
            
            this.checkLoggedIn().then(() =>{                        
                console.log("be vagy lépve")       
            }).catch((err) => {
                console.log('ki vagy lépve')
                console.log(err)
            })
        }
       
    }    
}
</script>