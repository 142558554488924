<template>
    <div id="termekek" class="products py-5">
        <div class="container-xl">
            <h2 class="font-header display-2 fw-900 text-primary text-uppercase text-center mb-5 mt-0 mt-md-5">
                Játékban részt vevő termékek
            </h2>
            
            <div class="row">
                <div class="col-2 d-none d-xxl-block"></div>
                <div class="col-12 col-xxl-8 mb-0 mb-md-5">
                    <p class="text-center">
                        <span>
                            Bármely Győri Édes, Pilóta, Korpovit, Dörmi, belVita vagy Milka keksz termékek, melyeket Magyarországon valamennyi Auchan áruházban vagy az Auchan Online Áruházában (    
                        </span>
                        <a class="text-primary fw-bold" href="https://online.auchan.hu/" target="_blank">https://online.auchan.hu/</a>
                        <span>
                            ) vásároltak a promóció ideje alatt. A választék áruházanként eltérő lehet.
                        </span>
                        
                    </p>
                </div>        
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div 
                            v-for="n in 6"
                            :key="'products-' + n"
                            class="col-4 d-flex justify-content-center"
                        >
                            <img 
                                :src="imgUrl(n)" 
                                class="img-fluid py-4" 
                                alt="jana termékek"
                            >
                        </div>
                    </div>
                </div>
                
                
                
            </div>    
            
            

            <div class="row">
                <div class="col-12 my-4 my-md-5">
                    <div class="d-flex flex-column">
                        <p class="text-center mb-5">A termékek képe illusztráció.</p>
                        <a class="btn btn-primary mx-auto" href="/pdf/resztvevo_termekek.pdf" target="_blank" @click="setGtm('Részt vevő termékek listája')">
                            Részt vevő termékek listája
                        </a>                        
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    methods: {
        imgUrl(nr){
            return '/imgs/prod-' + nr + '.png'
        },
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>