<template>
    <div id="belepes" class="login bg-light pt-5">
        <div class="container-xl">
            <h2 class="font-header display-2 fw-900 text-primary text-uppercase text-center mt-5">Bejelentkezés</h2>               
                <div class="row">
                    <div class="col-1 d-none d-xxl-block"></div>
                           <!-- ITT KEZDŐDIK A FORM --> 
                            <ValidationObserver 
                                class="col-12 col-xxl-10"
                                ref="observer" 
                                v-slot="{ handleSubmit }"
                            >
                                <form 
                                    class="row form-inner-container"
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            label="E-mail cím"
                                            name="email"
                                            placeholder="pl.: molnarkata@gmail.com"
                                            tooltip="A játékos e-mail címe amit regisztrációnál használt."
                                            v-model="form.email"
                                            rules="required|email"
                                        />
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            type="password"
                                            label="Jelszó"
                                            name="password"
                                            placeholder="Minimum 6 karakter"
                                            tooltip="Regisztráció során megadott jelszó: legalább 6 karakternek kell lennie és speciális karaktereket nem tartalmazhat."
                                            v-model="form.password"
                                            rules="required|min:6"
                                        />
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center mt-2 mb-0 my-sm-4">
                                                <input 
                                                    class="btn btn-primary btn-submit" 
                                                    type="submit" 
                                                    value="Bejelentkezés"
                                                >
                                            </div>
                                        </div>     
                                    </div>                                                                
                                    <div v-if="loginError" class="alert alert-danger">{{loginError}}</div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center mt-2">                                       
                                                <router-link :to="{name:'ForgottenPsw'}"                                                    
                                                    class="text-primary btn btn-link fw-bold ls-small text-center mt-0"
                                                >
                                                    Elfelejtett jelszó?
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>                     
                  </div>      
            </div>                
        </div>    
</template>

<script>
import CustomInput from '@/components/base/CustomInput.vue'
import { ValidationObserver } from 'vee-validate'
// import errorMessages from "@/setup/errorMsgs.json"

export default {
    components: {
        ValidationObserver,
        CustomInput        
    },
    data () {
        return {
           form: {
                email: '',
                password: ''
            }, 
            loginError: ""
        }
    },
    methods: {
        onSubmit(){        
            let _this = this       

            this.login(this.form.email, this.form.password).then((res)=>{
                if (res.data.status == true){
                    //reset form
                    if (this.$route.name == 'EmailVerification'){
                        this.$router.push({name:'Home', params:{ scrollTo: 'palyazat'}})
                    } else {
                        this.setFormDefaultState()    
                    }
                    
                }
                                
           }).catch((err)=>{            
                //this.setErrorMsgs(err)
                _this.setErrorMsgs(err).catch(()=>{
                    this.loginError = "Sikertelen bejelentkezés"

                    setTimeout(() => {
                        this.loginError = ""
                    }, 5000);
                })
           })
        },
        setFormDefaultState(){
            this.form = {
                email: '',
                password: ''
            }
        },
        goToError(err){            
            
            this.setErrorMsgs(err).then(()=>{
                let firstError = document.querySelector('#loginModal .input-error')
                if (firstError)
                    firstError.scrollIntoView()                             
            })                                       
        },
        // setErrorMsgs(err){
        //     console.log(err)
        //     // return new Promise(resolve => {
        //     //   var ret  = {};
        //     //   for (var i in err.response.data.error){
        //     //        ret[i] = errorMessages[i][err.response.data.error[i]]
        //     //   }              
              
        //     //   this.$refs.observer.setErrors(ret);              
        //     //   resolve()
        //     // });
        //     this.loginError = "Sikertelen bejelentkezés"

        //     setTimeout(() => {
        //         this.loginError = ""
        //     }, 5000);
        // },
    }
}
</script>