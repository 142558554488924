<template>
    <div class="row" id="formResultBlock">
        <div class="col-12 col-xl-10 offset-xl-1">
            <div class="bg-light form-inner-container-small form-inner-container-shadow">
                <!-- SIKERES ÜZENET -->
                <div v-if="type == 'success'" class="text-center">
                    <h3 class="text-primary mb-5 text-uppercase fw-bold">Köszönjük, hogy beküldted a bizonylat adatait!</h3>
                    <img class="mb-5" src="/imgs/ic-success-form.svg" />                    
                    <p class="mb-5">Ne feledd, a nyereményjáték 2022.07.27-ig tart! Növeld esélyedet és játssz újra! <br/>Az eredeti bizonylat sértetlen, ép állapotban történő megőrzésének határideje 2022. augusztus 27.!</p>                    
                </div>
                <!-- LIMITEK ELÉRÉSE -->
                <div v-else-if="type == 'limit_reached'" class="text-center">
                    <h3 class="text-primary mb-5 text-uppercase">Sikertelen pályázatbeküldés</h3>
                    <img class="mb-5" src="/imgs/ic-not-success-form.svg" />
                    <!-- TODO: szövegek kellenek -->
                    <p v-if="errorCode == 'maximum_daily_uploads'">Egy Játékos egy adott napon csak 3 Pályázatot küldhet be.</p>
                    <p v-if="errorCode == 'limit_reached_for_that_day'">Egy adott vásárlási napra vonatkozóan egy játékos csak 2 Pályázatot küldhet be.</p>
                    <p v-if="errorCode == 'maximum_uploads'">Egy játékos összesen maximum 30 db Pályázatot küldhet be.</p>
                    <p v-if="errorCode == 'nav_code'">Egy vásárlást igazoló blokk adatai kizárólag egy érvényes Pályázatban küldhetők be.</p>
                </div>
                <!-- SIKERTELEN ÜZENET -->
                <div v-else class="text-center">
                    <h3 class="text-primary mb-5 text-uppercase">Sikertelen pályázatbeküldés</h3>
                    <img class="mb-5" src="/imgs/ic-not-success-form.svg" />                    
                    <p class="mb-5">Lehet, hogy elütöttél valamit vagy ezzel a blokkal már játszottál. Ellenőrizd a blokkot, és írd be újra a adatokat!</p>
                    <p class="fw-bold mb-5">Tölts fel egy másik kódot!</p>
                </div>
                <div class="text-center">
                    <button class="mt-0  btn btn-primary btn-primary--large" @click="$emit('back-to-form')">
                        új beküldés
                    </button>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
/**
 * limit errors:
 * - maximum_daily_uploads : 5
 * - maximum_uploads: 30
 * - limit_reached_for_that_day : 2
 */

export default {
    props:{
        type:{
            required:true,
            type: String
        },
        errorCode:{
            required:false,
            type:String
        }
    },
  
}
</script>