<template>
  <div>
    <div v-if="$store.state.promoStatus != 'after' ">    
      <Header />          
      <Hero />    
      <!-- <ForgottenEmail /> -->
      <Rules />
      <div v-if="!this.$store.state.user">
        <LogIn  />
        <Register />
      </div>
      <div 
        v-else 
        class="send-form-bg"
      >
        <SendForm @compSend="isNewCompSended = true" @resetCompSend="isNewCompSended = false"/>
        <MySendings :getMyNewSendings="isNewCompSended" />
        <MyData />
      </div>
      <Winners />
      <Products />
      <Contact />
      <Footer />            
    </div>
    <!-- promo is ended -->
    <div v-else>
        <Header />          
        <HeroClosed/>  
        <div v-if="!this.$store.state.user">
          <LogIn  />          
        </div>
        <div v-else>
          <MySendings :getMyNewSendings="isNewCompSended" />
          <MyData />
        </div>
        <Winners />
        <Contact />
        <Footer />  
    </div>
  </div>
  
</template>

<script>
// import ForgottenEmail from "@/components/ForgottenEmail.vue"
import Header from "@/components/Header.vue"
import Hero from "@/components/Hero.vue"
import HeroClosed from "@/components/HeroClosed.vue"
import Rules from "@/components/Rules.vue"
import LogIn from "@/components/LogIn.vue"
import Register from "@/components/Register.vue"
import SendForm from "@/components/SendForm.vue"
import MySendings from "@/components/MySendings.vue"
import MyData from "@/components/MyData.vue"
import Winners from "@/components/Winners.vue"
import Products from "@/components/Products.vue"
import Contact from "@/components/Contact.vue"
import Footer from "@/components/Footer.vue"

export default {
  components: {
    // ForgottenEmail,
    Header,
    Hero,
    HeroClosed,
    Rules,
    LogIn,
    Register,
    SendForm,
    MySendings,
    MyData,
    Winners,
    Products,
    Contact,
    Footer
  },  
  data(){
    return{
      isNewCompSended : false
    }
  }  
}
</script>