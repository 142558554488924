<template>
    <div id="adataim" class="my-data pt-0 pb-5">
        <div class="container-xl">
            
            <div class="row">
                <div class="col-12">
                    <h2 class="font-header display-2 fw-900 text-primary text-uppercase text-center mb-3 mb-md-5 mt-md-5">
                        Kezelt adataim 
                    </h2>
                </div>
            </div>

            <div class="row">
                <div class="col-1 d-none d-xxl-block"></div>
                <div 
                    class="col-12 col-xxl-10"
                >
                    <div class="row ">
                        <div class="col-12">
                            <!-- KEZELT ADATAiM -->
                            <div class="bg-light form-inner-container-small form-inner-container-shadow mb-4">
                                <button 
                                    class="btn btn-collapse collapsed fw-bold" 
                                    type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapse1" 
                                    aria-expanded="true" 
                                    aria-controls="collapseExample">
                                        Kezelt adataim
                                </button>
                                <!-- COLLAPSE 1 CONTENT -->
                                <div class="collapse" id="collapse1">
                                    <div class="">
                                        <div
                                            class="my-4" 
                                            v-html="text">
                                            
                                        </div>
                                        <div 
                                            v-for="(input, idx) in inputs"
                                            :key="idx"
                                        >
                                            <label>{{input.label}}</label>
                                            <input 
                                                class="form-control mb-4" 
                                                type="text" 
                                                disabled="disabled"
                                                :placeholder="input.content"
                                            >
                                        </div>  
                                    </div><!-- end of COLLAPSE 1 CONTENT -->
                                </div><!-- end of COLLAPSE 1 -->
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        
                        <!-- ADATOK TÖRLÉSE -->
                        <div class="col-12">
                            <div class="bg-light form-inner-container-small form-inner-container-shadow mb-4">
                                <button 
                                class="btn btn-collapse collapsed fw-bold" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapse2" 
                                aria-expanded="true" 
                                aria-controls="collapseExample">
                                    Adatok exportálása/törlése
                                </button>
                                <!-- COLLAPSE 2 CONTENT -->
                                <div class="collapse" id="collapse2">
                                    <div class="col-12">
                                        <!-- FORM -->
                                        <ValidationObserver 
                                            class="mb-5"
                                            ref="observer" 
                                            v-slot="{ handleSubmit }"
                                        >
                                            <form 
                                                class="row mt-4"
                                                @submit.prevent="handleSubmit(onSubmit)"
                                            >
                                                <div class="col-12 mb-4">
                                                    <DropDown
                                                        label="Adatmódosítás"
                                                        name="topic"
                                                        placeholder="Válassz"
                                                        :options="options"
                                                        @selectedItem="form.type = $event"
                                                        rules="required"
                                                    />
                                                </div>
                                                
                                                <div class="col-12 mb-1">
                                                    <CustomInput
                                                        type="text"
                                                        label="Név"
                                                        name="change_name"
                                                        placeholder="pl.: Molnár Piroska"
                                                        tooltip="A játékos személyi igazolványába jegyzett teljes név."
                                                        v-model="form.name"
                                                        rules="required|min:6"
                                                        disabled
                                                    />
                                                </div>

                                                <div class="col-12 mb-1">
                                                    <CustomInput
                                                        label="E-mail cím"
                                                        name="change_email"
                                                        placeholder="pl.: molnarkata@gmail.com"
                                                        tooltip="A játékos e-mail címe."
                                                        v-model="form.email"
                                                        rules="required|email"
                                                        disabled
                                                    />
                                                </div>

                                                <div class="col-12 mb-2">
                                                    <CustomInput
                                                        label="Telefonszám"
                                                        name="change_phone"
                                                        placeholder="Pl.: +36 20 123 1234"
                                                        tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                                        v-model="form.phone"
                                                        disabled
                                                    />    
                                                </div>

                                                <div class="col-12 mb-3">
                                                    <CustomInput
                                                        label="Kedvenc Auchan áruház"
                                                        name="favourite_shop"
                                                        placeholder=""
                                                        v-model="form.favourite_shop"
                                                        disabled
                                                    />    
                                                </div>

                                                <div class="col-12">
                                                    <div class="col-12 d-flex justify-content-start">
                                                        <input 
                                                            class="btn btn-primary btn-submit" 
                                                            type="submit" 
                                                            value="Küldés"
                                                        >
                                                    </div>
                                                </div>
                                                <!-- form visszajelzés -->
                                                <div class="alert alert-success" v-if="formSendStatus == 'success'">
                                                    Sikeres üzenetküldés
                                                </div>
                                                <div class="alert alert-danger" v-if="formSendStatus == 'not_success'">
                                                    Sikertelen üzenetküldés
                                                </div>
                                            </form>
                                        </ValidationObserver> <!-- end of FORM -->

                                    </div><!-- end of COLLAPSE 2 CONTENT -->
                                </div><!-- end of COLLAPSE 2 -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import CustomInput from '@/components/base/CustomInput.vue'
import DropDown from '@/components/base/DropDown.vue'
export default {
    components: {
        ValidationObserver,
        CustomInput,
        DropDown
    },   
    data () {
        return {           
            options: [ 'Adataim törlése','Adataim exportálása/hordozása'],
            formSendStatus: null,
            text: 
            `<p>Amennyiben élni kívánsz a személyes adataid kezelését érintő -az Info Tv. és a GDPR adta- lehetőségekkel az alábbi módon kérheted adataid törlését, visszavonását, az adatkezelés korlátozását, valamint élhetsz adathordozási-, és tiltakozási jogoddal az adatkezelőnél. Ha töröltetni vagy módosítani kívánod az adataid az adatbázisunkban, ezt korlátozás és indokolás nélkül, valamint ingyenesen kérheted:</p>
            <p>- a Játék ideje alatt az auchanmondelez@a4c.hu e-mail e-mail címen, adattörlés tárggyal. A Játékos tudomásul veszi, hogy adatainak a Játék időtartama alatti törlése a Játékból való kizárását eredményezi, mivel Szervező az adatok hiányában nem tudja biztosítani a Játékos részvételét.</p>
            <p>- a fődíjak sorsolását (2022. augusztus 02.) követően adatkezeléssel és hírlevél küldéssel szemben tiltakozhatsz</p>   
            <li>az Auchan Magyarország Kft. (2040 Budaörs Sport u. 2-4, Marketing osztály) postacímére küldött nyilatkozattal, a borítékon az "Auchan Mondelez - adatvédelmi kérelem" megjelöléssel,</li>
            <li>vagy az Auchan áruházak vevőszolgálatain,</li>             
            <li>vagy az Auchan Info Centerében (email: info@auchan.hu vagy tel.: 0680 10 90 10)</li> 
            <li>vagy a www.auchan.hu hírlevél menüpontjában tett írásbeli nyilatkozattal</li>         
            `,
            inputs: [
                {
                    id: 1,
                    label: 'Név',
                    content: this.$store.state.user.last_name + ' ' + this.$store.state.user.first_name
                },
                {
                    id: 2,
                    label: 'E-mail cím',
                    content:  this.$store.state.user.email
                },
                {
                    id: 3,
                    label: 'Telefonszám',
                    content:  this.$store.state.user.phone
                },
                {
                    id: 4,
                    label: 'Kedvenc Auchan áruház',
                    content: this.$store.state.user.favourite_shop
                }
            ],
            form: {
                type: '',
                name: this.$store.state.user.last_name + ' ' + this.$store.state.user.first_name,
                email: this.$store.state.user.email,
                phone: this.$store.state.user.phone,
                favourite_shop: this.$store.state.user.favourite_shop
            }    
        }
    },   
    methods: {
        removeMsg(){
            setTimeout(() => {
                this.formSendStatus = null                

            }, 5000);
        },        
        onSubmit(){
            let _this = this

            this.post('profile/userrequest',{
                topic: _this.form.type,
                request: _this.form.type
            }).then((resp)=>{
                if (resp.data.status == true)
                {
                    this.formSendStatus = 'success'
                
                }
                this.removeMsg()
            }).catch(()=>{
                this.formSendStatus = 'not_success'
                this.removeMsg()
            })
        }
    }
}
</script>