<template>
    <div id="jatekszabaly" class="rules bg-blue pt-5 pb-5 pb-sm-5 pb-md-6">
        <div class="container-xl">
            <div class="row">
                <div class="col-12">
                    <h2 class="font-header display-2 fw-900 text-primary text-uppercase text-center mb-2 mb-md-5 mt-0 mt-md-5">
                        Játék menete
                    </h2>
                </div>
            </div>

            <div class="row">
            <div class="col-2 d-none d-xl-block"></div>   
            <div class="col-12 col-xl-8">
                <div 
                    v-for="rule in rules"
                    :key="rule.id"
                    class="rules-container mb-4"
                >
                    <div class="rules-icon d-flex flex-column align-items-center">
                        <img :src="rule.img" class="" alt="">
                        <p class="fs-3 fw-900 text-uppercase pt-2">{{rule.name}}</p>
                    </div>
                    
                    <div class="ms-0 ms-md-4 mt-4 mt-md-0" v-html="rule.content"></div>
                </div>
            </div>
            

            </div> <!-- end of ROW -->

            <div 
                class="row"
            >
                <div class="col-12 mt-3 mt-md-5">
                    <p class="fs-2 fw-900 text-center text-uppercase">Jó játékot, sok szerencsét kívánunk!</p>
                </div>
                <div class="p-0 p-md-5"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            rules: [
                {
                    id: 1,
                    name: "Vásárolj",
                    img: "/imgs/rules-1.svg",
                    content:
                    `<div class="d-flex align-items-center mb-3">
                        <p class="rules-number font-header fs-3 fw-900 me-4">01</p>
                        <p>
                            Vásárolj <b>legalább 1 db</b> a promócióban részt vevő <a href='#termekek' class="text-primary fw-bold">Győri Édes, Pilóta, Korpovit, Dörmi, belVita vagy Milka keksz terméket</a> az <strong>Auchan áruházakban</strong>, vagy az <strong>Auchan Online Áruházban</strong> (a 
                            <a href="https://online.auchan.hu" target="_blank" class="text-primary fw-bold">https://online.auchan.hu </a>
                                
                            oldalon keresztül).</p>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <p class="rules-number font-header fs-3 fw-900 me-4">02</p>
                        <p>
                            A nyereményjáték időtartama: <strong>2022. június 30. - július 27.</strong>
                        </p>
                    `

                },
                {
                    id: 2,
                    name: "Regisztrálj",
                    img: "/imgs/rules-2.svg",
                    content:
                    `<div class="d-flex align-items-center mb-3">
                        <p class="rules-number font-header fs-3 fw-900 me-4">03</p>
                        <p>Regisztrálj, majd töltsd fel a vásárlást igazoló bizonylat adatait és játssz a nyereményekért!</p>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <p class="rules-number font-header fs-3 fw-900 me-4">04</p>
                        <p>A <b>vásárlást igazoló eredeti bizonylat</b> sértetlen, ép állapotban történő megőrzése <b>2022. augusztus 27-ig szükséges.</b></p>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <p class="rules-number font-header fs-3 fw-900 me-4">05</p>
                        <p>Ne feledd, <b>minél többször vásárolsz</b> a promócióban részt vevő termékekből egyidejűleg legalább 1 db-ot, annyiszor <b>több esélyed van a nyerésre!</b></p>
                    </div>`
                },
                {
                    id: 3,
                    name: "Nyerj",
                    img: "/imgs/rules-3.svg",
                    content:
                    `<div class="d-flex align-items-center mb-3">
                        <p class="rules-number font-header fs-3 fw-900 me-4">06</p>
                        <p>A játék ideje alatt előre meghatározott <b>nyerőidőpontokban</b> (08:00-22:00 között) megnyerhető <b>naponta 3 db 10.000 Ft</b> értékű <b>Auchan ajándékkártya.</b>
                        </p>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <p class="rules-number font-header fs-3 fw-900 me-4">07</p>
                        <p>A <b>fődíj 1 fő nyertes részére 500.000 Ft értékű Auchan ajándékkártya.</b> A játék lezárását követően a fődíj <b>sorsolása: 2022. augusztus 02. 14 óra.</b></p>
                    </div>
                    `
                }
            ]
        }
    },
    methods:{
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatek_menete',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>