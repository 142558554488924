<template>
    <div id="nyertesek" class="winners bg-blue pt-5 pb-0">
        <div class="container-xl">
            <h2 class="font-header display-2 fw-900 text-primary text-uppercase text-center mb-5 mt-0 mt-md-5">
                Nyertesek
            </h2>
            
            <div class="row">
                <div class="col-2 d-none d-xxl-block"></div>
                <div class="col-12 col-xxl-8 mb-0 mb-md-5">
                    <p class="text-center mb-0">
                        Kedves Játékosunk!
                    </p>
                    <p class="text-center mb-4">
                        A napi nyeremények nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn) kerülnek kiértesítésre a Lebonyolító által küldött e-mail üzenetben.
                    </p>
                    <p class="text-center mb-0">
                        Sorsolás:
                    </p>
                    <p class="text-center">
                        A fődíj sorsolására 2022. augusztus 02-án 14:00 órakor kerül sor. A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)
                    </p>
                </div>        
            </div>

            <div class="row">
                <!-- DROPDOWN BTN -->
                <div 
                    v-for="win in wins"
                    :key="'winners' + win.id"
                    class="winners-collapse col-12 col-xl-6 d-flex flex-column justify-content-start align-items-center mb-5 mb-xl-0"
                    data-bs-toggle="collapse" 
                    :data-bs-target="'#winner-' + win.id" 
                    aria-expanded="false" 
                    aria-controls="collapseExample"
                >
                    <img 
                        :src="imgUrl(win.id)" 
                        class="img-fluid winners-card-img my-4"
                        alt="nyeremenyek">
                    
                    <div class="winners-collapse-button mt-3">
                        <img src="/imgs/winners-arrow.svg" alt="winners-arrow">
                    </div>                 

                    <!-- DROPDOWN LIST -->
                    <div 
                        class="winner-collapse-content collapse mt-3" 
                        :id="'winner-' + win.id"                       
                    >
                        <div  v-if="win.winners.length>0">
                            <p 
                                v-for="(winner, idx) in win.winners"
                                :key="'name' + idx"
                                class="mb-1 text-center"
                            >
                                {{winner.name}}
                            </p>
                        </div>
                        <div v-else>Hamarosan..</div>
                    </div>                    
                </div>
                

            </div>

            <div class="row">
                <div class="col-12 my-4 my-md-5">
                    <div class="d-flex flex-column">
                        <p class="text-center mb-5">A nyeremények képe illusztráció.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            winnersAll:[],
            wins: [
                {
                    id: 1,
                    name: 'fődíj',
                    type:'fodij',
                    line1: '',
                    line2: '',
                    line3: '',
                    winners: []
                },
                {
                    id: 2,
                    name: 'napi nyeremény',
                    type:'napi',
                    line1: '',
                    line2: '',
                    line3: '',
                    winners: []
                },                
            ]
        }
    },
    methods: {
        imgUrl(nr){
            return '/imgs/win-' + nr + '.png'
        },
        selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }
    },
    created(){
        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
          this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })
    }
    
}
</script>