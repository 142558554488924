<template>
  <div 
    class="navbar navbar-expand-xl fixed-top"
    :class="{ 'onScroll': !view.topOfPage}"
  >
    <div class="container-xl">
        <router-link class="navbar-brand" :to="{name: 'Home'}" v-scroll-to="'#hero'">
          <img src="/imgs/logo.svg" alt="Auchan">
        </router-link>
        <button 
            class="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            aria-expanded="false" 
            aria-label="Toggle navigation"
            @click="changeState" 
            target="nav-collapse"
        >
        <div
            class="custom-hamburger"
            :class="{'custom-hamburger-close' : isOpen}"
            @click="view.topOfPage = false"
        >
            <span></span>
            <span></span>
            <span></span>
        </div>

        </button>

        <div 
            class="collapse navbar-collapse" 
            id="navbarSupportedContent"
            ref="collapsebox"
        >
        <ul class="navbar-nav w-100 justify-content-center">
            <li class="nav-item"
                @click="isOpen=false;setGTM(item.name)"
                v-for="item in items" :key="'item' + item.id" 
            >
                <div 
                    v-if="menuVisible(item.logIn, item.logOut)"
                    class="nav-link active ps-md-1 ps-lg-1 ps-xl-4 pe-xl-4" 
                    aria-current="page"
                    @click="changeState" 
                >
                  <a 
                    v-if="item.id == 4"
                    href="/pdf/jatekszabalyzat.pdf"
                    target="_blank"
                  >
                    {{ item.name }}
                  </a>
                  <a 
                    v-else 
                    v-scroll-to="item.link"     
                    @click="goToHomePage(item.link)"               
                  >
                    {{ item.name }}
                  </a>
                </div>
            </li>           
        </ul>
        <div 
          v-if="this.$store.state.user"
          @click="changeState;logout();setGTM('Kijelentkezés')"
          class="d-flex justify-content-center"
        >
          <button class="btn btn-logout ms-0 ms-xl-3 ms-xxl-5">
            Kijelentkezés
          </button>
        </div>
        </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
        isOpen: false,
        view: {
            topOfPage: true
        },
        items: [
        {
          'id': 1,
          'name': 'Regisztráció',
          'link': '#regisztracio',
          'logIn': false,
          'logOut': true,
        },
        {
          'id': 2,
          'name': 'Belépés',
          'link': '#belepes',
          'logIn': false,
          'logOut': true,
        },
        {
          'id': 3,
          'name': 'Beküldés',
          'link': '#palyazat',
          'logIn': true,
          'logOut': false,
        },
        {
          'id': 4,
          'name': 'Játékszabály',
          'link': '#jatekszabaly',
          'logIn': true,
          'logOut': true,
        },
        {
          'id': 5,
          'name': 'Nyertesek',
          'link': '#nyertesek',
          'logIn': true,
          'logOut': true,
        },
        {
          'id': 6,
          'name': 'Részt vevő termékek',
          'link': '#termekek',
          'logIn': true,
          'logOut': true,
        },
        {
          'id': 7,
          'name': 'Kapcsolat',
          'link': '#kapcsolat',
          'logIn': true,
          'logOut': true,
        }
      ]
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
    created(){
    if ( this.$store.state.promoStatus == 'after' ){
        let menuItems = this.items.map((x) => x)        
        menuItems.shift()
        this.items = menuItems
    }
  },
  methods: {
    handleScroll(){
      if(window.pageYOffset>0){
        if(this.view.topOfPage) this.view.topOfPage = false
      } else {
        if(!this.view.topOfPage) this.view.topOfPage = true
      }
    },
    changeState(){
      this.isOpen=!this.isOpen
      if(!this.isOpen){
        setTimeout(() => {
          this.$refs.collapsebox.classList.remove('show')  
        }, 400)
      }
    },
    menuVisible(logIn, logOut){
      let state
      if(this.$store.state.user){
        logIn ? state = true : state = false
        return state 
      }
      else {
        logOut ? state = true : state = false
        return state
      }
    },
    goToHomePage(itemLink){
      
      if (this.$route.name != 'Home'){
        this.$router.push({name: 'Home'})

        setTimeout(() => {
                var element = document.getElementById(itemLink.substring(1))
                element.scrollIntoView()       
        }, 1000);
      }
    },
    setGTM(item){
      this.GTtrackGA4({
        'event' : 'event',
        'category': 'fejlec',
        'action': 'kattintas',
        'label': item,
        'menu': 'fejlec',
        'clicked_text':item,
        'action_type': 'menu_kattintas'
      })
    },
  }
  
  
}
</script>