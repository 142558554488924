<template>
    <div class="hero" id="hero">
        <div class="hero-gradient"></div>
        <div class="hero-container container-xl">
            <!-- NYEREMÉNYEK -->
            <div class="hero-top d-flex flex-column justify-content-center align-items-center">
                <img class="hero-img-1 mb-2" src="/imgs/win-1.png" alt="nyeremeny">
                <img class="hero-img-2" src="/imgs/win-2.png" alt="nyeremeny">
            </div>

            <!-- NYEREMÉNYEK -->
            <div class="row">
                <div class="col-12">
                    <p class="fs-2 fw-bold font-header text-light text-uppercase text-center my-4">
                        2022. Június 30.-Július 27.
                    </p>
                </div>
            </div>

            <!-- GOMBOK -->
            <div class="my-2 my-md-5">
                <div class="d-flex flex-column flex-md-row justify-content-center">
                    <a class="btn btn-outline-primary me-0 me-md-4 order-1 order-md-0" href="/pdf/jatekszabalyzat.pdf" target="_blank" @click="setGtm('Játékszabályzat')">
                        Játékszabályzat
                    </a>                            
                    <button
                        class="btn btn-primary mb-4 mb-md-0 order-0 order-md-1"
                        v-scroll-to="!$store.state.user ? '#regisztracio' : '#palyazat'"
                        @click="setGtm('Játszani szeretnék')"
                    >
                        Játszani szeretnék
                    </button>
                </div> 
            </div>
            
            <!-- NYEREMÉNYEK -->
            <div class="d-flex justify-content-center align-items-center py-1 py-md-4">
                <div class="hero-products">
                    <div 
                        v-for="(n, idx) in 6"
                        :key="idx"
                        class="hero-products-item"
                    >
                        <img 
                            :src="`/imgs/prod-${n}.png`" 
                            alt="termékek"
                            class="img-fluid"
                    >
                    </div>
                </div>
            </div>

            <div class="row pt-2 pb-5">
                <div class="col-1 d-none d-xxl-block"></div>
                <div class="col-12">
                    <p class="text-dark text-center my-2 my-md-4">
                        A nyeremények képe illusztráció.
                    </p>
                </div>
            </div>
            <div class="hero-bottom"></div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        imgUrl(nr){
            return '/imgs/decor-' + nr + '.png'
        },
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>