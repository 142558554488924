<template>
    <div class="select-date">
        <ValidationProvider 
            :rules="rules" 
            :name="$attrs.name" 
            :vid="name" 
            v-slot="{ valid, errors }"
            tag="div"            
        >
        <label :for="name"
        >   
            {{label}} 
            <span v-if="rules" 
                class="label-red"
            >
                *
            </span>
        </label>
    
        <datepicker
            wrapper-class="select-date--wrapper" 
            :language="hu"
            format="yyyy-MM-dd"
            :placeholder="placeholder" 
            v-model="value"
            :state="errors[0] ? false : (valid ? true : null)"
            @input="$emit('selectedDate', value)"
        />
        <!-- error heandling -->            
            <span class="input-error"  :class="{'active-error': errors[0]}">{{ errors[0] }}</span>
        </ValidationProvider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import {hu} from 'vuejs-datepicker/dist/locale'
export default {
    components: {
        Datepicker,
        ValidationProvider
    },
    props: {
        label: {
            required: false,
            type: String,
            default: ''
        },
        placeholder: {
            required: false,
            type: String,
            default: 'Válassz'
        },
        rules: {
            required: false,
            type: [Object, String],
            default: ''
        },
        name: {
            required: false,
            type: String,
        },
    },
    data () {
        return {
            hu: hu,
            value: null
        }
    }
}
</script>