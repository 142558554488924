<template>
    <!-- <ValidationProvider 
        :rules="rules" 
        :name="name" 
        :vid="name"
        v-slot="{ valid, errors }"
        tag="div"
    > -->
        <div class="custom-upload-container">
            <div class="custom-upload">
                <input 
                    class="custom-upload-input" 
                    type="file"
                    :id="name"
                    :name="name"                              
                    @input="$emit('input', $event.target.files[0])"   
                    @change="$emit('change', $event.target.files[0])"   
                />
                <button class="custom-upload-wrapper">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex ms-2">
                            <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.0209961" width="51" height="51" rx="25.5" fill="#1326CE"/>
                                <path d="M32 25.521C32 26.0835 31.5312 26.5522 31 26.5522H26.5V31.0522C26.5 31.5835 26.0312 32.021 25.5 32.021C24.9375 32.021 24.5 31.5835 24.5 31.0522V26.5522H20C19.4375 26.5522 19 26.0835 19 25.521C19 24.9897 19.4375 24.5522 20 24.5522H24.5V20.0522C24.5 19.4897 24.9375 19.021 25.5 19.021C26.0312 19.021 26.5 19.4897 26.5 20.0522V24.5522H31C31.5312 24.521 32 24.9897 32 25.521Z" fill="white"/>
                            </svg>
                            <div class="ps-3 d-flex flex-column justify-content-center">
                                <p class="text-primary text-start fw-bold mb-0">Blokk feltöltése</p>
                                <p class="mb-0">Max 5MB ● png vagy jpeg formátum</p>
                            </div>
                        </div>
                    </div>
                </button>
                <!-- tooltip -->
                <div 
                    v-if="tooltip"
                    class="custom-upload-tooltip"
                >
                    <div 
                        class="input-icon" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip" 
                        :title="tooltip"
                    >
                        <font-awesome-icon 
                            class="text-light" 
                            icon="info"/>
                    </div>
                </div>
            </div>
            <!-- error heandling -->            
            <!-- <div class="custom-upload-error input-error" :class="{'active-error': errors[0]}">{{ errors[0] }}</div> -->
        </div>
    <!-- </ValidationProvider> -->
</template>

<script>
// import { ValidationProvider } from 'vee-validate'

export default {
    components: {
            // ValidationProvider        
    },
    props: {
        tooltip: {
            required: false,
            type: String,
            default: null, 
        },
        value: {
            required: false,             
            default: null
        },
        name: {
            required: false,
            type: String,
            default: null
        },
        rules: {
            required: false,
            type: [Object, String],
            default: ""
        }
    },
}
</script>