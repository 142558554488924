<template>
    <div id="regisztracio" class="register pt-0 pt-md-5 pb-0 pb-md-5">
        <div class="container-xl">
            <div class="row">
                <div class="col-2 d-none d-xxl-block"></div>
                <div class="col-12 col-xxl-8 mb-0 mb-md-5">
                    <h2 class="font-header fw-900 display-2 text-primary text-uppercase text-center mb-4 mb-sm-5 mt-5">Regisztráció</h2>
                    <p class="text-dark text-center">Személyes adataid úgy add meg, hogy azokat személyi igazolványoddal és lakcímkártyáddal tudd igazolni, amennyiben nyerteseink között tudhatunk majd. Az adatok megadása, így a játékban való részvétel önkéntes.</p>
                </div>
            </div>
            
            <div class="row">
                <div class="col-1 d-none d-xxl-block"></div>
                <!-- ITT KEZDŐDIK A FORM -->
                <ValidationObserver 
                    class="col-12 col-xxl-10"
                    ref="observer" 
                    v-slot="{ handleSubmit }"
                >
                    <form
                        class="row form-inner-container form-inner-container-shadow" 
                        @submit.prevent="handleSubmit(onSubmit)"
                    > <!-- ITT ADOK HOZZÁ A BELSŐ KONTÉNERHEZ PADDINGET -->
                            
                            <!-- VEZETÉKNÉV -->
                            <!-- todo: regex:^[a-zA-Z\s]*$ -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Vezetéknév"
                                    name="last_name"
                                    placeholder="pl.: Molnár"
                                    tooltip="A játékos személyi igazolványába jegyzett vezetéknév."
                                    v-model="form.last_name"
                                    rules="required|min:2|customRegex"
                                />  
                            </div>
                            
                            <!-- KERESZTNÉV -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Keresztnév"
                                    name="first_name"
                                    placeholder="pl.: Kata"
                                    tooltip="A játékos személyi igazolványába jegyzett keresztnév."
                                    v-model="form.first_name"
                                    rules="required|min:2|customRegex"
                                />
                            </div>
                            
                            <!-- EMAIL -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="E-mail cím"
                                    name="email"
                                    placeholder="pl.: molnarkata@gmail.com"
                                    tooltip="A játékos e-mail címe."
                                    v-model="form.email"
                                    rules="required|email"                                    
                                />
                            </div>
                            <!-- EMAIL MÉGEGYSZER -->
                             <div class="col-12 col-md-6">
                                <CustomInput
                                    label="E-mail cím mégegyszer"
                                    name="emailConf"
                                    placeholder="pl.: molnarkata@gmail.com"
                                    tooltip="A játékos e-mail címe újra."
                                    v-model="form.emailConf"
                                    rules="required|email|confirmed:email"
                                />
                            </div>

                            <!-- JELSZÓ -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Jelszó"
                                    type="password"
                                    name="password"
                                    placeholder="Minimum 6 karakter"
                                    tooltip="Legalább 6 karakternek kell lennie és speciális karaktereket nem tartalmazhat."
                                    v-model="form.password"
                                    rules="required|min:6"
                                />
                            </div>

                            <!-- JELSZÓ MEGERŐSÍTÉS -->
                            <div class="col-12 col-md-6">
                                <CustomInput
                                    label="Jelszó mégegyszer"
                                    type="password"
                                    name="passconf"
                                    placeholder="Minimum 6 karakter"
                                    tooltip="Jelszó még egyszer."
                                    v-model="form.passconf"
                                    rules="required|confirmed:password"
                                />
                            </div>

                            <!-- TELEFONSZÁM -->
                            <div class="col-12 col-md-6 phone-wrap">
                                <div class="form-label fw-bold mb-0">Telefonszám</div>
                                <div class="d-flex w-100 align-items-start">
                                    <span class="me-2 d-block phone-prefix">+36</span>
                                    <CustomInput
                                        class="w-100"
                                        label=""
                                        name="phone"
                                        placeholder="Pl.: 20 123 1234"
                                        tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                        v-model="form.phone"
                                        v-mask="['##-###-####', '#-###-####']"
                                    />
                                </div>
                            </div>

                            <!-- KEDVENC ÁRUHÁZ -->
                            <div class="col-12 col-md-6">
                                <DropDown
                                    label="Kedvenc magyarországi Auchan áruház"
                                    name="favourite_shop"
                                    placeholder="Válassz egyet!"
                                    :options="shops"
                                    @selectedItem="form.favourite_shop = $event"
                                />
                            </div>

                            <!-- JÁTÉKSZABÁLY ELFOGADÁSA -->
                            <div class="col-12">
                                <CheckBox
                                    class="mt-4"
                                    name="rules"
                                    v-model="form.rules"
                                    :checked="form.rules"
                                    rules="required|acceptConditions"                                  
                                >
                                    <p 
                                        class="mb-0"
                                    >
                                        <span>A</span> 
                                        <span>&nbsp;</span>
                                        <a class="text-primary fw-bold" href="/pdf/jatekszabalyzat.pdf" target="_blank">Játékszabályt</a>
                                        <span>&nbsp;</span>
                                        <span>elfogadom.</span>
                                        <span class="text-red fw-bold">*</span>
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- ÁSZF ELFOGADÁSA -->
                            <div class="col-12">
                                <CheckBox
                                    class="my-2"
                                    name="privacy"
                                    v-model="form.privacy"
                                    :checked="form.privacy"
                                    rules="required|acceptConditions"
                                >
                                    <p class="mb-0"> 
                                        <span>Az</span>
                                        <span>&nbsp;</span>
                                        <a class="text-primary fw-bold" href="/pdf/adatkezelesi_tajekoztato.pdf" target="_blank">adatkezelési tájékoztatót</a>
                                        <span>&nbsp;</span>
                                        <span>megismertem, az abban foglaltakat tudomásul vettem.</span>
                                        <span class="text-red fw-bold">*</span>    
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- SZEMÉLYES ADATOK -->
                            <div class="col-12">
                                <CheckBox
                                    class="my-2"
                                    name="consent"
                                    v-model="form.consent"
                                    :checked="form.consent"
                                    rules="required|acceptConditions"   
                                >
                                    <p class="mb-0"> 
                                        <span>Hozzájárulok, hogy az itt megadott személyes adataimat az</span>
                                        <span>&nbsp;</span>
                                        Auchan Magyarország Kft.
                                        <span>&nbsp;</span>
                                        <span>a nyereményjáték lebonyolításával kapcsolatos célokból kezelje és nyertességem esetén nevem és lakóhelyem település megnevezésével jelen játék weboldalán megjelenítésre kerüljön.</span>
                                        <span class="text-red fw-bold">*</span>    
                                    </p>
                                </CheckBox>
                            </div>

                            <!-- FELIRATKOZÁS A HÍRLEVÉLRE -->
                            <div class="col-12">
                                <CheckBox
                                    class="my-2"
                                    name="marketing"
                                    v-model="form.marketing"
                                    :checked="form.marketing"
                                >
                                    <p class="mb-0">Feliratkozom az Auchan hírlevélre. Ezzel kijelentem, hogy megismertem és elfogadom az <a class="text-primary fw-bold" href="https://www.auchan.hu/adatvedelem" target="_blank">Auchan adatkezelési tájékoztatóját</a>, előzetesen és önkéntesen hozzájárulok, hogy az Auchan Magyarország Kft. átlagosan heti 1 db hírlevelet küldjön számomra, melyről bármikor egy kattintással leiratkozhatok.</p>
                                </CheckBox>
                            </div>

                            <div class="mt-5 mb-4 text-center">
                                <p>
                                    <span class="text-red fw-bold">*</span>
                                    <span>&nbsp;</span> 
                                    <span>A csillaggal jelölt mezők kitöltése szükséges.</span>
                                    
                                </p>
                            </div>
                            <div v-if="form.marketing == 1" class="row">
                                <p class="fw-bold text-center mb-5">Köszönjük a hírlevél feliratkozási szándékodat, kérjük add meg ezt a két adatot:</p>
                                
                                <!-- TITULUS -->
                                <div class="col-12 col-md-6 mb-4 mb-md-0">
                                    <DropDown
                                        label="Titulus"
                                        name="title"
                                        placeholder="Válassz egyet!"
                                        :options="titles"
                                        @selectedItem="form.title = $event"
                                        rules="required"
                                    />
                                </div>

                                <!-- SZÜLETÉSI ÉV -->
                                <div class="col-12 col-md-6">
                                    <SelectDate
                                        name="birth_date"
                                        label="Születési dátum" 
                                        placeholder="ÉÉ-HH-NN"
                                        @selectedDate="form.birth_date = dateTimeToSqlFormat($event)"
                                        rules="required"
                                    />
                                </div>
                            </div>

                            <div class="mb-4"></div>

                            <!-- SUBMIT --> 
                            <div class="row">
                                <div class="col-12 d-flex justify-content-center mb-2 mb-sm-4">
                                    <input 
                                        class="btn btn-primary btn-submit" 
                                        type="submit" 
                                        value="Regisztráció"
                                        @click="checkErrors()"
                                    >
                                </div>
                            </div>
                    </form>
                    
                </ValidationObserver> <!-- ITT VÉGZŐDIK A FORM -->
            </div>            
        </div>
        <!-- REGISTRATION RESULT MODAL -->
        <!-- <RegisterSuccessModal :email="sendedEmail" :isSuccessReg="regSuccess"/> -->
    </div>
    
</template>

<script>
/**
 * after the reg go to the reg result page
 */

import { ValidationObserver } from 'vee-validate'
import CustomInput from '@/components/base/CustomInput.vue'
import DropDown from '@/components/base/DropDown.vue'
import CheckBox from '@/components/base/CheckBox.vue'
import SelectDate from '@/components/base/SelectDate.vue'
// import RegisterSuccessModal from '@/components/RegisterSuccessModal.vue'
// import errorMessages from "@/setup/errorMsgs.json";
import {mask} from 'vue-the-mask'

export default {
    directives: {mask},
    components: {
        ValidationObserver,
        CustomInput,
        DropDown,
        CheckBox,
        SelectDate,
        // RegisterSuccessModal
    },
    data () {
        return {
            options: [],
            howOften:['Alkalmanként (félévente néhány alkalommal)', 'Csak a nyereményjátékban való részvétel kedvéért vásároltam','Rendszeresen (havonta)', 'Ritkán (évente egyszer, kétszer)' ],
            titles:['Nincs','Dr.', 'Prof.', 'Ifj.', 'Id.', 'Özv.'],
            years:['18-29', '30-39', '40-49', '50-59' ,'60+'],
            form: {
                first_name: '',
                last_name: '',
                email: '',
                emailConf:'',
                phone:'',
                password: '',
                passconf: '',
                favourite_shop: '',
                rules: null,
                privacy: null,
                consent: null,
                marketing: 0,
                title: '',
                birth_date:''                                           
           },
           /* surveyQuestions:[
               {
                   id:1,
                   question:"Hol találkoztál a nyereményjátékkal? (több válasz is adható)",
                   answers:[ 'Akciós újság', 'Áruházon belüli kommunikáció', 'Ismerős ajánlása', 'Egyéb']
               },
               {
                   id:2,
                   question: "Milyen gyakran vásárolsz Jana termékeket?",
                   answers:[]
               },
               {
                   id:3,
                   question: "Auchanon kívül hol vásárolsz ilyen terméket és miért?",
                   answers:[]
               },
                {
                   id:4,
                   question: "Milyen termékeknek örülnél a jövőben?",
                   answers:[]
               },
                {
                   id:4,
                   question: "Hány éves vagy?",
                   answers:[]
               }                  
           ],
           survey:[
                    {
                        question_number: 1,
                        answer: ""
                    },
                    {
                        question_number: 1,
                        answer: ""
                    },
                    {
                        question_number: 1,
                        answer: ""
                    },
                    {
                        question_number: 1,
                        answer: ""
                    },
                    {
                        question_number: 2,
                        answer: ""
                    },
                    {
                        question_number: 3,
                        answer: ""
                    },
                    {
                        question_number: 3,
                        answer: ""
                    },
                    {
                        question_number: 4,
                        answer: ""
                    },
                    {
                        question_number: 5,
                        answer: ""
                    }
                ] */            
           //regSuccess: false,
        //    sendedEmail: ""        
        }
    },
    computed:{
        shops(){
            return  this.$store.state.shops
        },
        formToSend()      {            
            let data = this.form             
            /* data.survey = [
                {
                    question_number: 1,
                    answer: this.survey[0].answer + ";" + this.survey[1].answer + ";" + this.survey[2].answer + ";" + this.survey[3].answer
                },
                {
                    question_number: 2,
                    answer: this.survey[4].answer
                },
                {
                    question_number: 3,
                    answer: this.survey[5].answer + ";" + this.survey[6].answer
                },
                {
                    question_number: 4,
                    answer: this.survey[7].answer
                },
                {
                    question_number: 5,
                    answer: this.survey[8].answer
                }
            ] */

            return data
        }
    },
    methods: {
        getItem(item){
            console.log(item)
            this.form.favorite_supermarket = item
        },
        getDate(date){
            console.log(date)
        },
        scrollToElement(element, diff){
            
            const offset = document.querySelector('.navbar').clientHeight + diff
            const bodyTop = document.body.getBoundingClientRect().top;
            const elementTop = element.getBoundingClientRect().top;
            const elementPos = elementTop - bodyTop;
            const offsetPos = elementPos - offset;

            window.scrollTo({
                top: offsetPos,
                behavior: 'smooth'
            });
        },
        onSubmit(){
            if (this.form.phone){
                this.form.phone = '+36-' + this.form.phone
            }

            var sendData = JSON.parse(JSON.stringify(this.formToSend));            

            this.post('register',sendData).then((res)=>{
                if (res.data.status == true){                    
                    //this.regSuccess = true                                  

                    //go to the reg 
                    this.$router.push({name: 'successReg', params: {result: 'success'}})
                    
                    //reset form
                    this.setFormDefaultState()       
                    this.setGtm('sikeres')                 
                }
                                
           }).catch((err)=>{                          
                //this.regSuccess = false   
                this.setGtm('sikertelen')                              
                this.goToError(err)                                                          
           })
      
        },
        analyzeErrors(err){
            return new Promise((resolve, reject) => {
                let errorsArray = Object.entries(err.response.data.error)
                for (const [errorName, errorValue] of errorsArray) {
                    // if (errorName == "email" && errorValue == "occupied" ){
                    //     this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'occupied'}})
                    //     reject()
                    // } 
                    if (errorName == 'maximum_registration_same_ip' && errorValue == 'limit_reached'){
                        this.$router.push({name: 'successReg', params: {result: 'not-success', details: 'limit_reached'}})
                        reject()
                    } else{
                        resolve()
                    }
                }
            })
        },
        checkErrors(){
                let firstError = document.querySelector('.register .input-error')
                if (firstError)                    
                    this.scrollToElement(firstError, 80)
        },
        goToError(err){            
            this.analyzeErrors(err).then(()=>{
                this.setErrorMsgs(err).then(()=>{                
                    let firstError = document.querySelector('.register .input-error.active-error')
                    if (firstError)
                        this.scrollToElement(firstError, 80)       
                }).catch(()=>{
                    this.$router.push({name: 'successReg', params: {result: 'not-success'}})
                })   
            })
                                              
        },      
        setFormDefaultState(){
            this.form = {
                first_name: '',
                last_name: '',
                email: '',
                emailConf:'',
                phone:'',
                password: '',
                passconf: '',
                favourite_shop: '',
                rules: null,
                privacy: null,
                consent: null,
                marketing: 0,
                title: '',
                birth_date:'',
                /* survey:[
                    {
                        question_number: 1,
                        answer: false
                    },
                    {
                        question_number: 2,
                        answer: false
                    },
                    {
                        question_number: 3,
                        answer: false
                    },
                    {
                        question_number: 4,
                        answer: false
                    },
                    {
                        question_number: 5,
                        answer: ""
                    },
                    {
                        question_number: 6,
                        answer: ""
                    },
                    {
                        question_number: 7,
                        answer: ""
                    },
                    {
                        question_number: 8,
                        answer: ""
                    },
                    {
                        question_number: 9,
                        answer: ""
                    }


                ] */                              
           }
        },
        setGtm(result){
            
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'regisztracio',
                'action': 'gomb_kattintas',                                
                'clicked_text':'Regisztráció',
                'action_type': 'gomb_kattintas',
                'label': result,
                'success': result
            })
        
        }
    },
    created(){
        
    },
    mounted(){
        
    }
}
</script>