<template>
    <div class="footer bg-primary py-5">
        <div class="text-light text-center container-xl d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <a class="mb-4 mb-lg-0" href="#">© 2022 A4C Marketing Kft. - Minden jog fenntartva.</a>
            <a 
                href="/pdf/adatkezelesi_tajekoztato.pdf" 
                target="_blank" 
                class="mb-4 mb-lg-0"
            >
                Adatkezelési tájékoztató.
            </a>
            <a href="mailto: auchanmondelez@a4c.hu">auchanmondelez@a4c.hu</a>
            <a href="#" v-scroll-to="'#hero'" class="scroll-up mt-4 mt-md-0"></a>
        </div>        
        <CookieBannerComp class="w-100  d-flex align-items-center justify-content-center justify-content-md-start mb-0"/>
    </div>
</template>


<script>
import CookieBannerComp from '@/plugins/cookiebanner/CookieBannerComp.vue'
export default {
    components: {
        CookieBannerComp
    }
}
</script>