<template>
    <div class="hero-closed  d-flex justify-content-center align-items-center">
        <div class="hero-closed-gradient"></div>
        <div class="hero-closed-container container-xl">
            <!-- TITLE -->
            <div class="row">
                <div class="col-1 d-none d-xxl-block"></div>
                <div class="col-12 col-xxl-10">
                    
                    <p class="fw-bold font-header display-1 text-center text-light text-uppercase ls-small mb-0">    
                        Nyereményjátékunk július 6-án lezárult.
                    </p>

                </div>
            </div> <!-- end of TITLE -->

            <div class="row">
                <div class="col-2 d-none d-xxl-block"></div>
                <div class="col-12 col-xxl-8">
                    <p class="text-light text-center my-2 my-md-5">
                        A vásárlást igazoló blokk eredeti példányát a nyereményjáték lezárulta után további 3 hónapig (2022. október 6-ig), illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni!
                    </p>
                    <p class="fs-2 text-light text-center text-uppercase mt-4 mt-md-0 mb-5">
                        Köszönjük, hogy velünk játszottál! A nyertesek listájához görgess lejjebb!
                    </p>
                    <div class="d-flex justify-content-center mt-5 mb-5 mb-md-0">
                        <button 
                            class="hero-closed-btn btn btn-primary mt-0 mt-md-3"
                             v-scroll-to="'#nyertesek'"
                        >
                            Nyertesek
                        </button>
                    </div>
                    
                </div>
            </div>


        </div>
    </div>
</template>

<script>
export default {
    methods: {
        imgUrl(nr){
            return '/imgs/decor-' + nr + '.png'
        }
    }
}
</script>