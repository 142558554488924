<template>
    <div id="palyazat" class="send-form pt-5 pb-0 pb-md-5">
        <div class="container-xl">
            <div class="row">                
                <div class="col-12 col-xxl-8 mb-0 mb-md-5 offset-xxl-2">
                    <h2 class="font-header display-2 fw-900 text-primary text-uppercase text-center mb-4 mb-sm-5 mt-0 mt-md-5">
                        Pályázat beküldés
                    </h2>
                    <p class="text-dark text-center mb-0">
                        Mielőtt elküldöd az adatokat, kérjük ellenőrizd le, hogy minden információt pontosan, a blokkon szereplő módon adtál-e meg! Utólag az elküldött adatokat nem lehet módosítani, csak a helyesen megadott blokk adatokkal válhatsz nyertessé.
                    </p>
                    <p class="text-dark text-center mb-0">
                        Csak a legalább 1 db a promócióban részt vevő Győri Édes, Pilóta, Korpovit, Dörmi, belVita vagy Milka keksz termékek vásárlását igazoló blokk vesz részt érvényesen a játékban.
                    </p>
                    <div class="d-flex justify-content-center">
                        <a 
                            class="text-primary fw-bold text-center cursor-pointer"
                            v-scroll-to="'#termekek'"
                        >
                            Beküldés előtt tájékozódj a játékban résztvevő termékekről!
                        </a>
                    </div>
                    
                </div>
            </div>
            
            <div class="row" v-if="$store.state.promoStatus == 'live'">                
                <!-- ITT KEZDŐDIK A FORM -->
                <div v-if="formSendedStatus == null" class="col-12 col-xxl-10 offset-xxl-1">
                    <ValidationObserver 
                        class=""
                        ref="observer" 
                        v-slot="{ handleSubmit }"
                    >
                        <form
                            class="row bg-light form-inner-container form-inner-container-shadow" 
                            @submit.prevent="handleSubmit(onSubmit)"
                        >
                                <div class="send-form-container h-100">
                                    
                                    <!-- FORM FIELDS -->
                                    <div class="send-form-form">
                                        <div class="row">
                                            
                                            <label for="">Vásárlás típusa <span class="text-primary fw-bold">*</span></label>
                                            <div class="col-12 mb-2">
                                                <CustomRadio
                                                    id="buy_type"
                                                    name="buy_type" 
                                                    firstLabel="Boltban vásárolt"
                                                    firstValue="shop"
                                                    secondLabel="Online vásárolt"
                                                    secondValue="online"
                                                    v-model="form.buy_type"
                                                    rules="required"
                                                    :firstCheck="form.buy_type"
                                                />
                                            </div>
                                            
                                            <!-- SHOP FORM -->
                                            <div v-if="form.buy_type == 'shop'">
                                                <div class="col-12 mb-4">
                                                    <DropDown
                                                        label="Hol történt a vásárlás?"
                                                        name="where_bought"
                                                        placeholder="Válassz"
                                                        :options="offlineShops"
                                                        @selectedItem="form.where_bought = $event"
                                                        rules="required"
                                                    />
                                                </div>

                                                <div class="col-12 mb-2">
                                                    <CustomInput
                                                        v-mask="'########'"
                                                        label="AP kód"
                                                        name="ap_code"
                                                        tooltip="AP betűk után A és 8 db számjegy."
                                                        v-model="form.ap_code"
                                                        rules="required|min:8"
                                                        code
                                                    />
                                                </div>

                                                <div class="col-12 mb-4">
                                                    <CustomInput
                                                        label="NAV kód"
                                                        v-mask="{mask: 'FFFFF', tokens: hexTokens}"
                                                        name="nav_code"
                                                        placeholder=""
                                                        tooltip="5 karakter"
                                                        v-model="form.nav_code"
                                                        rules="required|length:5"
                                                    />
                                                </div>

                                                <!-- MOBIL KÉP -->
                                                <div class="send-form-img-mobile col-12">
                                                    <img v-if="form.buy_type == 'shop' " src="/imgs/receipt.png" class="img-fluid mb-4" alt="blokk fotó" />
                                                    <img v-else src="/imgs/online_szamla.jpg" class="img-fluid mb-4" alt="online számla" />
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-lg-6">
                                                        <SelectDate
                                                            name="purchase_time"
                                                            label="Vásárlás időpontja" 
                                                            placeholder="ÉÉ-HH-NN"
                                                            @selectedDate="form.date_of_bought = dateTimeToSqlFormat( $event)"
                                                            rules="required"
                                                        />
                                                    </div>
                                                    
                                                    <div class="col-12 col-lg-6 mb-4">
                                                        <SelectTime 
                                                            name="time_of_bought"
                                                            label="" 
                                                            placeholder="ÓÓ:PP"
                                                            @selectedTime="form.time_of_bought = timeToSqlFormat($event)"
                                                            rules="required"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- ONLINE FORM -->
                                            <div v-else>
                                                <div class="col-12 mb-2">
                                                    <CustomInput
                                                        label="Megrendelés száma"
                                                        name="online_code"
                                                        v-mask="{mask: 'GGGG', tokens: hexTokens}"
                                                        tooltip="A rendelés számának utolsó 4 karaktere."
                                                        v-model="form.online_code"
                                                        rules="required|length:4"                                                                                                   
                                                    />
                                                </div>
                                                <!-- MOBIL KÉP -->
                                                <div class="send-form-img-mobile col-12">
                                                    <img v-if="form.buy_type == 'shop' " src="/imgs/receipt.png" class="img-fluid mb-4" alt="blokk fotó" />
                                                    <img v-else src="/imgs/online_szamla.jpg" class="img-fluid mb-4" alt="online számla" />
                                                </div>
                                                <div class="col-12">
                                                    <SelectDate
                                                        name="purchase_time"
                                                        label="Vásárlás időpontja" 
                                                        placeholder="ÉÉ-HH-NN"
                                                        @selectedDate="form.date_of_bought = dateTimeToSqlFormat( $event)"
                                                        rules="required"
                                                    />
                                                </div>
                                            </div>                                         
                                        </div>
                                    </div><!-- end of FORM FIELDS -->
                                    
                                    <!-- RECIPE IMG -->
                                    <div class="send-form-img">
                                        <img v-if="form.buy_type == 'shop' " src="/imgs/receipt.png" class="img-fluid mb-4" alt="blokk fotó" />
                                        <img v-else src="/imgs/online_szamla.jpg" class="img-fluid mb-4" alt="online számla" />
                                    </div><!-- end of RECIPE IMG -->
                                </div>


                                <!-- FILE UPLOAD -->
                                <div class="col-12">
                                    <CustomFileUpload 
                                        class="mt-2"
                                        name="receipt"
                                        v-model="form.receipt"
                                        tooltip="A blokkot/szállítólevelet teljes terjedelmében felülről kell lefotózni és beküldeni. Amennyiben egy fényképre nem fér rá, úgy légy kedves több részletben, átfedésekkel lefotózni a blokkot, hogy a részletekből össze tudjuk illeszteni a teljes blokkot. A blokk/szállítólevél kép(ek) mindegyikének olvashatónak kell lennie."
                                        @change="handleFilesUpload()"
                                    />
                                </div>

                                <!-- FELTÖLTÖTT FÁJLOK -->
                                <div class="col-12 col-lg-6">                                                                              
                                    <div class="uploaded-files">                              
                                    <label class="form-label mt-3" v-if="uploadedFiles.length > 0">Feltöltött fájlok:</label>                              

                                    <div v-if="form.receipt">
                                        <span class="upload-text">{{ form.receipt.name }}</span>
                                        <div
                                            class="delete del-1"
                                            @click="delUploadedFile(form.receipt)"
                                        ></div>
                                    </div>
                                    <div v-if="form.receipt2">
                                        <span class="upload-text">{{ form.receipt2.name }}</span>
                                        <div
                                            class="delete del-2"
                                            @click="delUploadedFile(form.receipt2)"
                                        ></div>
                                    </div>
                                    <div v-if="form.receipt3">
                                        <span class="upload-text">{{ form.receipt3.name }}</span>
                                        <div
                                            class="delete del-3"
                                            @click="delUploadedFile(form.receipt3)"
                                        ></div>
                                    </div>  
                                    <div v-if="form.receipt4">
                                        <span class="upload-text">{{ form.receipt4.name }}</span>
                                        <div
                                            class="delete del-4"
                                            @click="delUploadedFile(form.receipt4)"
                                        ></div>
                                    </div>     
                                    <div v-if="form.receipt5">
                                        <span class="upload-text">{{ form.receipt5.name }}</span>
                                        <div
                                            class="delete del-5"
                                            @click="delUploadedFile(form.receipt5)"
                                        ></div>
                                    </div>                             
                                    </div>
                                    <!-- fájl feltöltés error -->
                                    <div
                                        class="uploaded-error input-error ms-0"
                                        ref="uploadederror"
                                    ></div>
                                    <span v-if="imageError" class="error input-error">A blokk képének feltöltése kötelező</span>
                                    <span v-if="imageApiError" class="error input-error">Hiba a fájl feltöltése során. Kérjük, töltsön fel egy új fájlt.</span>
                                </div>

                                <!-- ACCEPT TERMS -->
                                <div class="class-12">
                                    <CheckBox
                                        class="mt-4"
                                        name="acknowledge"
                                        v-model="form.acknowledge"
                                        :checked="form.acknowledge"
                                        rules="required|acceptConditions"   
                                    >
                                        <p class="mb-0"> 
                                            <span>Igazolom, hogy az adott bizonylaton szerepel legalább 1 db a Játékban részt vevő Győri Édes, Pilóta, Korpovit, Dörmi, belVita vagy Milka keksz termék.</span>
                                            <span>&nbsp;</span>
                                            <span class="text-red fw-bold">*</span>    
                                        </p>
                                    </CheckBox>
                                </div>

                                <!-- NOTE -->
                                <div class="col-12">
                                    <div class="col-12 d-flex justify-content-center">
                                        <p class="text-primary ls-small text-center mt-5 mb-4">
                                            <span class="text-red fw-bold">*</span>
                                            <span>&nbsp;</span>
                                            <span class="text-dark">A csillaggal jelölt mezők kitöltése szükséges.</span>
                                        </p>
                                    </div>
                                </div>
                                
                                <!-- SUBMIT --> 
                                <div class="col-12">
                                    <div class="col-12 d-flex justify-content-center my-2 my-sm-4">
                                        <input 
                                            class="btn btn-primary btn-submit" 
                                            type="submit" 
                                            value="Pályázat beküldése"
                                        >
                                    </div>
                                </div>
                        </form>
                        
                    </ValidationObserver> <!-- ITT VÉGZŐDIK A FORM -->
                </div>
                <div v-else>
                    <SendFormResult 
                        :type="formSendedStatus" 
                        :errorCode="formSendedErrorCode"
                        @back-to-form="goBackToForm()"
                    />
                </div>
                
            </div>
            <div class="row" v-else-if="$store.state.promoStatus == 'before'">
                 <div class="col-12 col-xxl-10 offset-xxl-1">
                    <div class="bg-light form-inner-container-small form-inner-container-shadow text-center">
                        A promóció időszaka még nem kezdődött el.
                    </div>
                 </div>
            </div>
        </div>
    </div>
    
</template>

<script>
/**
 * FORM.BUY_TYPE
 * The form and API post is change there's fields by form.buy_type
 * 
 * FORMSENDEDSTATUS
 * formSendedStatus switched between the form and SendFormResult
 * if formSendedStatus == null --> show the form
 * if formSendedStatus != null --> possible values: success, not_success --> show the SendFormResult
 * 
 *  
 */

import { ValidationObserver } from 'vee-validate'
import CustomRadio from '@/components/base/CustomRadio.vue'
import CustomInput from '@/components/base/CustomInput.vue'
import DropDown from '@/components/base/DropDown.vue'
import CheckBox from '@/components/base/CheckBox.vue'
import CustomFileUpload from '@/components/base/CustomFileUpload.vue'
import SelectDate from '@/components/base/SelectDate.vue'
import SelectTime from '@/components/base/SelectTime.vue'
import {mask} from "vue-the-mask"
import SendFormResult from '@/components/SendFormResult.vue'

export default {
    directives: {mask},
    components: {
        ValidationObserver,
        CustomRadio,
        CustomInput,
        DropDown,
        CheckBox,
        SelectDate,
        SelectTime,
        CustomFileUpload,
        SendFormResult
    },
    data () {
        return {
            options: ['első', 'második', 'harmadik'],            
            form: {
                buy_type: 'shop',
                where_bought: '',
                ap_code: '',
                nav_code: '',
                online_code:'',
                date_of_bought: '',
                time_of_bought: '',   
                purchase_time:'',                                             
                acknowledge: 0,
                receipt:'',
                receipt2:'',
                receipt3:'',
                receipt4:'',
                receipt5:''
            },
            maxFileUploadNum:5,
            imageError:false,
            uploadedFiles: [],
            imageApiError:false,
            hexTokens: {
                F: {
                    pattern: /[0-9a-fA-F]/,
                    transform: v => v.toLocaleUpperCase()
                },
                G: {
                    pattern: /[0-9a-zA-Z]/,
                    transform: v => v.toLocaleUpperCase()
                }
            },
            formSendedStatus: null,
            formSubmitted:false,
            formSendedErrorCode:null
        }
    },
    computed:{
        productPcs(){
            // 1 - 24 pcs generate            
            let pcs =[...Array(25).keys()]            
            return pcs.splice(2)          
        },
        purchase_time(){
            //for the API send
            return this.form.date_of_bought + ' ' + this.form.time_of_bought
        },
        offlineShops(){
            let shopAll = this.$store.state.shops.map((x) => x)        
            shopAll.shift()
            return shopAll
        }
    },
    methods: {
        defaultFormState(){
            this.form = {
                buy_type: 'shop',
                where_bought: '',
                ap_code: '',
                nav_code: '',
                online_code:'',
                date_of_bought: '',
                time_of_bought: '',                
                purchase_time:'',
                acknowledge: 0,
                receipt:'',
                receipt2:'',
                receipt3:'',
                receipt4:'',
                receipt5:''
           }
        },
        goToError(err){            
            let _this = this
            //scroll to the first error
          this.setErrorMsgs(err).then(()=>{                               
              let firstError = document.querySelector('.send-form .input-error')
              if (firstError){
                  _this.scrollToElement(firstError, 80)       
              }
              
          })                                       
        },
        goToResultBlock(){
            let _this = this
            
            setTimeout(() => {
                let resultBlock = document.getElementById('formResultBlock')
                //resultBlock.scrollIntoView()
                _this.scrollToElement(resultBlock, 80)
            }, 1000);
        },
        onSubmit(){
            let _this = this
            this.imageApiError = false

            //kötelező blokkot feltölteni
            if (!_this.form.receipt) {
                _this.imageError = true;
                return;
            }

            if (!_this.formSubmitted) {
                _this.formSubmitted = true           //don't start two form submit line 

                let formData = _this.getFormData()             

                //var sendData = JSON.parse(JSON.stringify(this.form));   

                _this.post('profile/step1/upload', formData)
                .then((response) => {                   
                    //SUCCESS FORM SENDED
                    if (response.data.status === true) {                        
                        _this.formSendedStatus = 'success' //for success mgs
                        _this.goToResultBlock() // go to the success msg block
                        _this.$emit('compSend')
                        let GTMcategory = 'palyazat_bekuldes'
                        _this.setGtm('sikeres', GTMcategory)
                    } 

                    _this.defaultFormState()
                    _this.formSubmitted = false
                    //this.$nextTick(() => this.$refs.observer.reset());

                }).catch(function (err) {
                    // NOT SUCCESS FORM SENDED
                    console.log('Sikertelen ág')
                    console.log(err);   
                    _this.formSubmitted = false
                    let GTMcategory = null

                    let errorsArray = Object.entries(err.response.data.error)
                    for (const [errorName, errorValue] of errorsArray) {
                        //limitek elérése - napi max, vásárlási nap blokk max, össz feltöltés
                        if (errorName == "maximum_daily_uploads" || errorName == 'maximum_uploads' || errorName == 'purchase_time' && errorValue == 'limit_reached_for_that_day' || errorName == 'nav_code') {
                            _this.formSendedStatus = 'limit_reached'
                            _this.formSendedErrorCode = errorName != 'purchase_time' ? errorName : errorValue
                            _this.goToResultBlock()  
                            //GTM 
                            if ( errorName == 'maximum_daily_uploads'){
                                GTMcategory = 'palyazat_bekuldes_napi_limit'
                            } else if ( errorName == 'maximum_uploads' ){
                                GTMcategory = 'palyazat_full_max_limit'
                            } else if( errorName == 'nav_code'){
                                GTMcategory = 'palyazat_ervenytelen_nav_code'
                            }else  if (errorName == 'purchase_time' && errorValue == 'limit_reached_for_that_day'){
                                GTMcategory = 'palyazat_bekuldes_egy_napi_vasarlas_limit'
                            }                       
                        } else if ( errorName == 'ap_code' && errorValue == 'same_send' || errorName == 'online_code' && errorValue == 'same_send'){
                            _this.formSendedStatus = 'not_success'
                            _this.goToResultBlock()                             
                            GTMcategory = 'palyazat_bekuldes_ismetlodo'
                        } else if (errorName === "receipt"){
                            _this.imageApiError = true
                        } else {
                             _this.goToError(err)                
                             GTMcategory = 'egyeb_hiba'                                  
                            //this.$nextTick(() => this.$refs.observer.reset());
                        }
                    }                                                                        
                    _this.setGtm('sikertelen', GTMcategory)               

                });
            }
        },
        goBackToForm(){
            this.defaultFormState()
            this.uploadedFiles = []
            this.formSendedErrorCode = null
            this.formSendedStatus = null
            this.$emit('resetCompSend')
        },
        setGtm(result, GTMcategory){
             this.GTtrackGA4({
                'event' : 'event',
                'category': GTMcategory,
                'action': 'bekuldes_gomb_kattintas',
                'label': result,                
                'button': 'kodfeltoltes',
                'clicked_text':'Beküldés',
                'success': result
            })
        },
        handleFilesUpload() {
            console.log('handleFilesUpload')

            let _this = this;
            //remove the error msg if there was
            this.$refs.uploadederror.innerHTML = "";

            // let uploadedItem = this.$refs.receipt.files[0];
            let uploadedItem = document.getElementById('receipt').files[0];

            //check file size 5Mb max
            let maxFileSize = 1024 * 1024 * 5;
            let fileSize = uploadedItem.size;

            //check file fomat
            // let isGoodExtension = this.checkFileFormat(uploadedItem.name);

            //check file size - max 2Mb
            if (fileSize > maxFileSize) {
                this.$refs.uploadederror.innerHTML =
                    "Túl nagy a fájl mérete (max. 5Mb)";
                // } else if (isGoodExtension == false) {
                //   this.$refs.uploadederror.innerHTML = "Nem megfelelő formátum.";
            } else {
                if (_this.uploadedFiles.length < parseInt(_this.maxFileUploadNum)) {
                //max 5 item could be uploaded

                let nameIndex = _this.uploadedFiles.find(
                    (item) => item.name == uploadedItem.name
                );

                if (nameIndex == undefined) {
                    _this.uploadedFiles.push(uploadedItem);
                } else {
                    this.$refs.uploadederror.innerHTML = "Megegyező fájl név."
                }
                } else {
                _this.$refs.uploadederror.innerHTML = "Nem tölthető fel több fájl."
                }
            }

            this.fillReceipts();

            //set the default state for the input type=file
            //this.$refs.receipt.value = ""
            document.getElementById('receipt').value = ""

            if (this.form.receipt != null) {
                this.imageError = false;
            }
        },
        fillReceipts() {
            let _this = this;

            _this.form.receipt = null;
            _this.form.receipt2 = null;
            _this.form.receipt3 = null;
            _this.form.receipt4 = null;
            _this.form.receipt5 = null;

            this.uploadedFiles.forEach(function (item, i) {
                if (i == 0) {
                _this.form.receipt = item;
                } else if (i == 1) {
                _this.form.receipt2 = item;
                } else if (i == 2) {
                _this.form.receipt3 = item;
                } 
                else if (i == 3) {
                _this.form.receipt4 = item;
                } else if (i == 4) {
                _this.form.receipt5 = item;
                }
            });
        },
        delUploadedFile(delItem) {
            let _this = this
            let delItemIndex = this.uploadedFiles.indexOf(delItem);

            if (delItemIndex != -1) {
                _this.uploadedFiles.splice(delItemIndex, 1);
            }

            this.$refs.uploadederror.innerHTML = "";
            this.fillReceipts();
        },
        getFormData(){
            let _this = this
            let formData = new FormData();
            
            formData.append("buy_type", _this.form.buy_type);
            formData.append("where_bought", _this.form.where_bought);

            if (_this.form.buy_type == 'shop'){
                formData.append("ap_code", 'APA' + _this.form.ap_code);
                formData.append("online_code", '');
                formData.append("nav_code", _this.form.nav_code);
            } else {
                formData.append("online_code", _this.form.online_code);
                formData.append("ap_code", '');
                formData.append("nav_code", '');
            }            
                                                
            if (_this.form.buy_type == 'shop' ){
                formData.append("purchase_time", _this.purchase_time);
            } else {
                formData.append("purchase_time", _this.form.date_of_bought + ' 00:00');
            }
                        
            formData.append("acknowledge", _this.form.acknowledge);
            formData.append("receipt", _this.form.receipt);
            formData.append("receipt2", _this.form.receipt2);
            formData.append("receipt3", _this.form.receipt3);
            formData.append("receipt4", _this.form.receipt4);
            formData.append("receipt5", _this.form.receipt5);

            return formData
        },
    }
}
</script>